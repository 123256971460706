import React, {MouseEventHandler, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '../icons/CloseIcon';

type Props = {
    label?: string,
    onClick: MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean,
    // Usually the cancel button sits left to the confirm button. Thus, by default there is a margin to the right of the cancel button.
    omitMargin?: boolean
}

function CancelButton({label, onClick, disabled, omitMargin = false}: Readonly<Props>) {
    const [t] = useTranslation();
    const theLabel = useMemo(() => label ?? t('Button.CANCEL'), [label, t]);

    return <button className={`btn btn-transparent ${omitMargin ? '' : 'mr-2'}`} onClick={onClick} disabled={disabled} data-cy="cancelButton">
        <CloseIcon/>
        <span className="ml-2">{theLabel}</span>
    </button>;
}

export default CancelButton;
