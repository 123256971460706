import React, {MouseEventHandler, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import CheckIcon from '../icons/CheckIcon';

type Props = {
    label?: string,
    onClick: MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean
}

function ConfirmButton({label, onClick, disabled}: Readonly<Props>) {
    const [t] = useTranslation();
    const theLabel = useMemo(() => label ?? t('Button.CONFIRM'), [label, t]);

    return <button className="btn btn-primary" onClick={onClick} disabled={disabled} data-cy="confirmButton">
        <CheckIcon/>
        <span className="ml-2">{theLabel}</span>
    </button>;
}

export default ConfirmButton;
