import FullWeekdayOption from '../enums/FullWeekdayOption';

export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export const timeRegexSecondPrecision = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$';

export const timeRegexMinutePrecision = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';

export function formatDate(currentLanguage, date, showWeekday = false) {
    return formatDateAndTime(date, currentLanguage, showWeekday, false);
}

export function isDateEqual(date1, date2) {
    return withoutTime(new Date(date1)).getTime() - withoutTime(date2).getTime() === 0;
}

export function formatDateAndTime(date, currentLanguage = 'en-US', showWeekday = false, showTime = true) {
    if (!date) {
        return '';
    }

    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };

    if (showTime) {
        options['hour'] = 'numeric';
        options['minute'] = 'numeric';
    }

    if (showWeekday) {
        options['weekday'] = 'short';
    }

    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    return date.toLocaleDateString(currentLanguage, options);
}

export function getWeekDayShort(date, currentLanguage = 'en-US') {
    return date.toLocaleDateString(currentLanguage, {weekday: 'short'});
}

export function formatDateShort(date, currentLanguage = 'en-US') {
    if (!date) {
        return '';
    }
    const options = {
        month: '2-digit',
        day: '2-digit'
    };
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return date.toLocaleDateString(currentLanguage, options);
}

export function formatDateYearAndMonth(date, currentLanguage = 'en-US') {
    if (!date) {
        return '';
    }
    const options = {
        year: 'numeric',
        month: 'long'
    };
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return date.toLocaleDateString(currentLanguage, options);
}

export function getDateRangeAsArray(startDate, endDate) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
        dateArray.push(convertDateToIsoDateString(new Date(currentDate)));
        currentDate = addDays(currentDate, 1);
    }
    return dateArray;
}

export function getWeekday(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    // In Javascript Sunday has index 0; in Gabel1 it has index 6
    const javascriptWeekdayIndex = date.getDay();
    const gabel1WeekdayIndex = javascriptWeekdayIndex > 0 ? javascriptWeekdayIndex - 1 : 6;

    return Object.values(FullWeekdayOption)[gabel1WeekdayIndex];
}

//Source: https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
export function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return  week number
    return weekNo;
}

export function getTwoMonthsBefore() {
    const result = new Date();
    result.setMonth(result.getMonth() - 2);
    return result;
}

export function getCurrentMonday() {
    const today = new Date(Date.now());
    return getMondayForDate(today);
}

export function getMondayForDate(date) {
    let monday = new Date(date);

    // Go to monday if now is a sunday.
    if (monday.getDay() !== 0) {
        monday = new Date(monday.getTime() + DAY_IN_MILLISECONDS);
    }

    // Go to monday if now is a saturday.
    if (monday.getDay() !== 6) {
        monday = new Date(monday.getTime() + 2 * DAY_IN_MILLISECONDS);
    }

    while (monday.getDay() !== 1) {
        monday = new Date(monday.getTime() - DAY_IN_MILLISECONDS);
    }

    return monday;
}

export function getToday() {
    const today = new Date(Date.now());
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    return today;
}

export function getDayInThreeWeeks() {
    const today = new Date(Date.now());
    const dayInThreeWeeks = addDays(today, 22);
    dayInThreeWeeks.setHours(0);
    dayInThreeWeeks.setMinutes(0);
    dayInThreeWeeks.setSeconds(0);
    dayInThreeWeeks.setMilliseconds(0);
    return dayInThreeWeeks;
}

export function getFirstDayOfCurrentMonth() {
    const today = new Date(Date.now());
    const currentMonth = today.getMonth();
    const year = today.getFullYear();
    return new Date(year, currentMonth, 1);
}

export function compareDate(day1, day2) {
    day1.setHours(0);
    day1.setMinutes(0);
    day1.setSeconds(0);
    day1.setMilliseconds(0);

    day2.setHours(0);
    day2.setMinutes(0);
    day2.setSeconds(0);
    day2.setMilliseconds(0);

    return day1.getTime() - day2.getTime() > 0;
}

export function equalDate(day1, day2) {
    day1.setHours(0);
    day1.setMinutes(0);
    day1.setSeconds(0);
    day1.setMilliseconds(0);

    day2.setHours(0);
    day2.setMinutes(0);
    day2.setSeconds(0);
    day2.setMilliseconds(0);

    return day1.getTime() - day2.getTime() === 0;
}

export function dateDifference(day1, day2) {
    day1.setHours(0);
    day1.setMinutes(0);
    day1.setSeconds(0);
    day1.setMilliseconds(0);

    day2.setHours(0);
    day2.setMinutes(0);
    day2.setSeconds(0);
    day2.setMilliseconds(0);

    return day1.getTime() - day2.getTime();
}

export function isDateAtLeastThreeWeeksAhead(date) {
    return compareDate(date, getDayInThreeWeeks()) || equalDate(date, getDayInThreeWeeks())
}

/**
 * example of ISO-Date-String: 2020-10-30
 * @param date
 * @returns {string}
 */
export function convertDateToIsoDateString(date) {
    if (!(date).getTimezoneOffset) {
        // date not properly formatted, do nothing
        return date;
    }
    const tzoffset = (date).getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, -1);
    return localISOTime.split('T')[0];
}

export function getPrevDay(from) {
    const date = from || getToday();
    return addDays(date, -1);
}

export function getNextDay(from) {
    const date = from || getToday();
    return addDays(date, 1);
}

export function getNextDayAt(hour, minute, second) {
    const today = getToday();
    today.setHours(hour);
    today.setMinutes(minute);
    today.setSeconds(second);
    today.setMilliseconds(0);
    return addDays(today, 1);
}

export function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function minusDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}

export function min(date1, date2) {
    return date1 < date2 ? date1 : date2;
}

export function max(date1, date2) {
    return date1 > date2 ? date1 : date2;
}

export function isDateContainedIn(range, date) {
    return withoutTime(range.from) < withoutTime(date) && withoutTime(range.to) > withoutTime(date);
}

export function withoutTime(inputDate) {
    const newDate = new Date(inputDate.getTime());
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

export function sortWeekdays(weekdays) {
    const sorter = {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7
    };
    return weekdays.sort(function comparer(a, b) {
        let day1 = a.toLowerCase();
        let day2 = b.toLowerCase();
        return sorter[day1] - sorter[day2];
    });
}

export function isOverlapping(news, newsToCompare) {
    return !((new Date(news.startDate)).getTime() - (new Date(newsToCompare.endDate)).getTime() > 0
        || (new Date(news.endDate)).getTime() - (new Date(newsToCompare.startDate)).getTime() < 0);
}
