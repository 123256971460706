import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Dropdown from '../../../common/component/form/Dropdown';
import PageLeaveGuard from '../../../common/component/PageLeaveGuard';
import Week from '../../../common/component/Week';
import ErrorCode from '../../../common/enums/ErrorCode';
import {convertDateToIsoDateString} from '../../../common/util/DateUtil';
import {showError, showMessage} from '../../../message/action/messageActions';
import subscriptionApi from '../../api/subscriptionApi';
import '../../style/Subscription.scss';
import SubscriptionDateDurationPicker from '../SubscriptionDateDurationPicker';
import SubscriptionFooterRow from '../SubscriptionFooterRow';

function ChildSubscriptionEdit({
                                   child,
                                   subscription: initialSubscription,
                                   onUpdate,
                                   childMenuGroups,
                                   minOrderableDate
                               }) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const catererBusinessDays = useSelector(state => state.caterer.timeManagementChild?.businessDays);

    const [isValid, setIsValid] = useState(true);
    const [subscription, setSubscription] = useState(initialSubscription);
    const [hasChanges, setHasChanges] = useState(false);
    const [validation, setValidation] = useState({
        endDate: true,
        startDate: true,
        childId: true,
        weekdays: true
    });

    const validate = useCallback(() => {
        const newValidation = {
            startDate: !!subscription.startDate && (!subscription.pauseStartDate || new Date(subscription.pauseStartDate) >= new Date(subscription.startDate)),
            endDate: !subscription.endDate ||
                (new Date(subscription.endDate) > new Date(subscription.startDate) &&
                    (!subscription.pauseEndDate || new Date(subscription.endDate) >= new Date(subscription.pauseEndDate))),
            pauseStartDate: !!subscription.pauseStartDate === !!subscription.pauseEndDate &&
                (!subscription.pauseStartDate || new Date(subscription.pauseStartDate) >= new Date(subscription.startDate)),
            pauseEndDate: !!subscription.pauseStartDate === !!subscription.pauseEndDate &&
                (!subscription.endDate || !subscription.pauseEndDate || new Date(subscription.endDate) >= new Date(subscription.pauseEndDate)),
            childId: !!subscription.childId,
            weekdays: !!subscription.weekdays && subscription.weekdays.length > 0
        };
        setValidation(newValidation);

        // Check if all fields are not empty
        const isValidUpdated = Object.values(newValidation).every(field => !!field);
        setIsValid(isValidUpdated);
        return isValidUpdated;
    }, [subscription]);

    useEffect(() => {
        if (!isValid) {
            validate();
        }
    }, [subscription, isValid, validate]);

    const handleSubscriptionChange = useCallback((field, value) => {
        if ((field === 'startDate' || field === 'endDate' || field === 'pauseStartDate' || field === 'pauseEndDate') && !!value) {
            value = convertDateToIsoDateString(value);
        }

        setSubscription((currentValue) => {
            return {
            ...currentValue,
            [field]: value
            }
        });
        setHasChanges(true);
    }, []);

    const handleRemovePause = useCallback(() => {
        setSubscription((currentValue) => {
            return {
                ...currentValue,
                pauseStartDate: null,
                pauseEndDate: null
            };
        });
        setHasChanges(true);
    }, []);

    function selectWeekday(weekdayOption) {
        const weekdays = subscription.weekdays ? [...subscription.weekdays] : [];
        if (weekdays.includes(weekdayOption)) {
            weekdays.splice(weekdays.indexOf(weekdayOption), 1);
        } else {
            weekdays.push(weekdayOption);
        }
        handleSubscriptionChange('weekdays', weekdays);
    }

    function save() {
        if (validate()) {
            setHasChanges(false);
            subscriptionApi.createOrUpdate(subscription).then((res) => {
                if (res.data.success) {
                    if (onUpdate) {
                        onUpdate(res.data.result);
                    }
                    dispatch(showMessage('Subscription.SAVE_SUCCESS',
                        {child: `${child.firstName} ${child.lastName}`}));
                } else if (res.errorCode === ErrorCode.DUPLICATE_KEY.code) {
                    dispatch(showError('Subscription.DUPLICATION_ERROR'));
                }
            });
        }
    }

    function remove() {
        subscriptionApi.remove(subscription.id).then(response => {
            if (response.data.success) {
                setHasChanges(false);
                dispatch(showMessage('Subscription.CANCELLATION_SUCCESS', {child: `${child.firstName} ${child.lastName}`}));
                onUpdate(null);
            }
        });
    }

    const menuGroupOptions = childMenuGroups.map(childMenuGroup => {
        return ({
            value: childMenuGroup.id,
            label: childMenuGroup.name
        });
    });

    return (
        <>
            <div className="row">
                <SubscriptionDateDurationPicker
                    isSubscriptionPresent={!!subscription.id}
                    startDate={subscription.startDate}
                    endDate={subscription.endDate}
                    pauseStartDate={subscription.pauseStartDate}
                    pauseEndDate={subscription.pauseEndDate}
                    initialStartDate={initialSubscription.startDate}
                    minOrderableDate={minOrderableDate}
                    handleStartDateChanged={value => handleSubscriptionChange('startDate', value)}
                    handleEndDateChanged={value => handleSubscriptionChange('endDate', value)}
                    handlePauseStartDateChanged={value => handleSubscriptionChange('pauseStartDate', value)}
                    handlePauseEndDateChanged={value => handleSubscriptionChange('pauseEndDate', value)}
                    handleRemovePause={handleRemovePause}
                    isStartDateValid={validation.startDate}
                    isEndDateValid={validation.endDate}
                    isPauseStartDateValid={validation.pauseStartDate}
                    isPauseEndDateValid={validation.pauseEndDate}
                />
                <div className="col-12 col-md-4 col-lg-4 order-3 order-md-2">
                    <p className="mt-3 mt-md-0">{t('Subscription.WEEKDAYS')}</p>
                    <div className="my-3">
                        <Week
                            availableDays={catererBusinessDays}
                            selectedDays={subscription.weekdays}
                            onSelectWeekday={selectWeekday}
                            isValid={validation.weekdays}
                            errorMessage={t('Validation.REQUIRED')}
                        />
                    </div>
                </div>
                {
                    menuGroupOptions.length > 0 &&
                    <div className="col-12 col-md-3 order-3 d-none d-lg-block">
                        <p className="mt-3 mt-md-0">{'Menülinie:'}</p>
                        {
                            menuGroupOptions.length === 1 ?
                                <>{menuGroupOptions[0].label}</> :
                                <Dropdown
                                    options={menuGroupOptions}
                                    value={{value: subscription.menuGroupId}}
                                    onChange={(value) => handleSubscriptionChange('menuGroupId', value.value)}
                                />
                        }

                    </div>
                }

            </div>
            <SubscriptionFooterRow
                isSubscriptionPresent={!!subscription.id}
                onSave={save}
                cancelLabel={t('Subscription.CANCELLATION_CONFIRMATION', {child: `${child.firstName} ${child.lastName}`})}
                onRemove={remove}
            />
            <PageLeaveGuard hasChanges={hasChanges}/>
        </>
    );
}

export default ChildSubscriptionEdit;
