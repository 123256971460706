import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Link, useLocation} from 'react-router-dom';
import LoginCredentials from '../../../common/component/form/LoginCredentials';
import ErrorCode from '../../../common/enums/ErrorCode';
import {showError} from '../../../message/action/messageActions';
import loginApi from '../../../user/api/loginApi';
import '../../style/PasswordReset.scss';

function PasswordReset() {
    const [t] = useTranslation();
    const {search} = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const catererId = useSelector(state => state.caterer.catererId);
    const [success, setSuccess] = useState(false);
    const query = new URLSearchParams(search);
    const token = query.get('token');
    const email = query.get('email');
    const [infoText, setInfoText] = useState('PasswordReset.INFO_RESET');
    const [validToken, setValidToken] = useState(false);
    const [errorCode, setErrorCode] = useState(null);

    const [password, setPassword] = useState({
        password: null,
        passwordRepeat: null
    });
    const [isValidated, setIsValidated] = useState(false);
    const [validation, setValidation] = useState({
        password: true,
        passwordRepeat: true
    });

    useEffect(() => {
        if (catererId) {
            loginApi.checkForValidPasswordReset(token, email, catererId).then((res) => {
                if (res.data.success) {
                    if (res.data.result) {
                        setValidToken(true);
                    }
                } else {
                    setErrorCode(res.errorCode);
                }
            });
        }
    }, [catererId, email, token]);

    useEffect(() => {
        if (isValidated) {
            validate();
        }
    }, [isValidated, password]);

    function handlePasswordChange(field, value) {
        setPassword({
            ...password,
            [field]: value
        });
    }

    function savePassword() {
        if (validate()) {
            loginApi.passwordReset(token, email, password.password, catererId).then((res) => {
                if (res.data.success) {
                    setSuccess(true);
                    setInfoText('PasswordReset.RESET_SUCCESS');
                } else {
                    dispatch(showError(t('Error.GENERAL')));
                    setSuccess(false);
                }
            }).catch(() => {
                setSuccess(false);
            });
        }
    }

    function validate() {
        const newValidation = {
            password: !!password.password,
            passwordRepeat: !!password.passwordRepeat && password.password === password.passwordRepeat
        };
        setIsValidated(true);
        setValidation(newValidation);

        // Check if password is valid
        return Object.values(newValidation).every(field => !!field);
    }

    function handlePasswordReset() {
        history.push('/request-password-reset');
    }

    if (errorCode && ErrorCode.NOT_REGISTERED_FOR_CATERER.code === errorCode) {
        return <div className="container">
            <h1>{t('PasswordReset.RESET')}</h1>
            <div>{t('Error.NOT_REGISTERED_FOR_CATERER')}</div>
        </div>;
    }
    if (!validToken) {
        return (
            <div className="container">
                <h1>{t('PasswordReset.RESET')}</h1>
                <Trans i18nKey="PasswordReset.INVALID_TOKEN"
                       components={{1: <a href="#" onClick={handlePasswordReset}/>}}
                />
            </div>
        );
    }

    return (
        <div id="password-reset-text" className="container mx-auto">
            <h1>{success ? t('PasswordReset.RESET_SUCCESS_HEADER') : t('PasswordReset.RESET')}</h1>
            <p>{t(infoText)}</p>
            {
                success ?
                    <div className="text-center">
                        <Link to={{pathname: '/', search: `username=${email}`}} className="btn btn-primary">{t('Confirmation.GO_TO_LOGIN')}</Link>
                    </div>
                    :
                    <>
                        <LoginCredentials
                            login={password}
                            onChange={handlePasswordChange}
                            validation={validation}
                            showEmail={false}
                            showPasswordRepeatInOneLine={false}
                            passwordLabel={'PasswordReset.NEW_PASSWORD'}
                        />

                        <div className="button-footer text-right">
                            <button className="btn btn-primary" onClick={savePassword}>{t('PasswordReset.RESET_BUTTON')}</button>

                        </div>
                    </>
            }
        </div>
    );
}

export default PasswordReset;
