import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import isEmail from 'validator/es/lib/isEmail';
import TextInput from '../../../common/component/form/TextInput';
import loginApi from '../../../user/api/loginApi';
import '../../style/PasswordReset.scss';

function PasswordResetRequest() {
    const [t] = useTranslation();
    const catererId = useSelector(state => state.caterer.catererId);
    const [success, setSuccess] = useState(false);
    const [infoText, setInfoText] = useState('PasswordReset.INFO_REQUEST');
    const [email, setEmail] = useState(null);
    const [isValidated, setIsValidated] = useState(false);
    const [validation, setValidation] = useState(true);

    useEffect(() => {
        if (isValidated) {
            validate();
        }
    }, [email]);

    function sendPasswordResetRequest() {
        if (validate()) {
            loginApi.requestPasswordReset(email, catererId).then((res) => {
                // Even if no login was found for this email, show the success page:
                setSuccess(true);
                setInfoText('PasswordReset.MAIL_SENT');
            }).catch(() => {
                setSuccess(false);
            });
        }
    }

    function validate() {
        setIsValidated(true);
        setValidation(!!email && isEmail(email));

        // Check if password is valid
        return !!email && isEmail(email);
    }

    return (
        <div id="password-reset-text" className="container mx-auto">
            <h1>{success ? t('PasswordReset.MAIL_SENT_HEADER') : t('PasswordReset.REQUEST')}</h1>
            {
                success &&
                <div className="zack zack-hooray mx-auto mb-3 mt-n4"/>
            }
            <p>{t(infoText)}</p>
            {
                !success &&
                <>
                    <TextInput
                        id="email"
                        type="email"
                        label={t('User.EMAIL')}
                        value={email}
                        onChange={value => setEmail(value)}
                        required={true}
                        isValid={validation}
                        errorMessage={!validation && !!email ? t('Validation.INVALID_EMAIL') : null}
                        focusOnLoad={true}
                    />

                    <div className="button-footer text-right">
                        <button className="btn btn-primary" onClick={sendPasswordResetRequest}>{t('PasswordReset.REQUEST_PASSWORD')}</button>
                    </div>
                </>
            }
        </div>
    );
}

export default PasswordResetRequest;
