import {Reducer} from 'redux';
import {InitialAction} from '../../../common/reducer/reducers';
import {CatererType} from '../../../types/caterer/CatererType';
import {ParentType} from '../../../types/ParentType';
import {
    REMOVE_FROM_PARENTS_SEARCH_RESULT,
    RESET_PARENTS_SEARCH,
    RESET_PARENTS_SEARCH_EXECUTED,
    SET_CATERERS,
    SET_PARENTS_SEARCH_CATERER_ID,
    SET_PARENTS_SEARCH_RESULT,
    SET_PARENTS_SEARCH_TERM,
    SupportDataActions
} from './supportDataActions';

export type SupportDataState = {
    caterersList: Array<CatererType>,
    caterersById: Record<number, CatererType>,
    caterersLoaded: boolean,
    searchForParents: SupportParentSearch
}

type SupportParentSearch = {
    searchTerm?: string,
    catererId?: number,
    searchExecuted: boolean,
    result: Array<ParentType>,
    preventForward: boolean
}

const initialState: SupportDataState = {
    caterersList: [],
    caterersById: {},
    caterersLoaded: false,
    searchForParents: {
        searchExecuted: false,
        result: [],
        preventForward: false
    }
};

const supportDataReducer: Reducer<SupportDataState, SupportDataActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    switch(action.type) {
        case SET_CATERERS:
            const caterersById: Record<number, CatererType> = {};
            action.caterers.forEach((caterer: CatererType) => {caterersById[caterer.id] = caterer});

            return {
                ...state,
                caterersList: action.caterers,
                caterersById,
                caterersLoaded: true
            };
        case SET_PARENTS_SEARCH_TERM:
            return {
                ...state,
                searchForParents: {
                    ...state.searchForParents,
                    searchTerm: action.searchTerm
                }
            };
        case SET_PARENTS_SEARCH_CATERER_ID:
            return {
                ...state,
                searchForParents: {
                    ...state.searchForParents,
                    catererId: action.catererId,
                }
            };
        case SET_PARENTS_SEARCH_RESULT:
            return {
                ...state,
                searchForParents: {
                    ...state.searchForParents,
                    result: action.parents,
                    searchExecuted: true,
                    preventForward: false
                }
            };
        case RESET_PARENTS_SEARCH_EXECUTED:
            return {
                ...state,
                searchForParents: {
                    ...state.searchForParents,
                    searchExecuted: false
                }
            };
        case RESET_PARENTS_SEARCH:
            return {
                ...state,
                searchForParents: {
                    searchTerm: undefined,
                    catererId: undefined,
                    result: [],
                    searchExecuted: false,
                    preventForward: false
                }
            };
        case REMOVE_FROM_PARENTS_SEARCH_RESULT:
            return {
                ...state,
                searchForParents: {
                    ...state.searchForParents,
                    result: state.searchForParents.result.filter(foundParent => !action.parentIds.includes(foundParent.id)),
                    preventForward: true
                }
            };
    }

    return state;
};

export default supportDataReducer;
