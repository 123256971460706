import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Prompt} from 'react-router';
import {useHistory} from 'react-router-dom';
import {navigate} from '../action/pageLeaveActions';
import ConfirmationDialog from './form/ConfirmationDialog';

function PageLeaveGuard({hasChanges, onLeave}) {
    const [t] = useTranslation();
    const history = useHistory();

    const dispatch = useDispatch();
    const confirmedLocation = useSelector(state => state.pageLeave.nextLocation);

    const [showPageLeaveConfirmation, setShowPageLeaveConfirmation] = useState(false);
    const [requestedLocation, setRequestedLocation] = useState(null);

    useEffect(() => {
        if (confirmedLocation) {
            // This will trigger handlePageLeave(). handlePageLeave() will return true for confirmedLocation.
            history.push(confirmedLocation);
            // Reset after navigating to the location:
            dispatch(navigate(null));
        }
    }, [confirmedLocation, dispatch, history]);

    /* The value for the message parameter of Prompt may be a function (to which the requested location is given as parameter).
     * If it returns true, navigation to nextLocation will be done. If it returns false, the navigation will be blocked.
     * So, we show the ConfirmationDialog in the latter case and set a confirmed location if the user confirms the navigation.
     */
    function handlePageLeave(nextLocation) {
        if ((confirmedLocation && confirmedLocation === nextLocation.pathname) || nextLocation.pathname === window.location.pathname) {
            return true;
        }

        setRequestedLocation(nextLocation.pathname);
        setShowPageLeaveConfirmation(true);
        return false;
    }

    function confirmPageLeave() {
        if (onLeave) {
            onLeave();
        }
        setShowPageLeaveConfirmation(false);
        dispatch(navigate(requestedLocation));
    }

    function stayHere() {
        setShowPageLeaveConfirmation(false);
        setRequestedLocation(null);
    }

    return (<>
        <Prompt when={hasChanges} message={handlePageLeave}/>
        <ConfirmationDialog
            open={showPageLeaveConfirmation}
            title={t('Common.UNSAVED_CHANGES_TITLE')}
            body={t('Common.UNSAVED_CHANGES_MESSAGE')}
            onConfirm={confirmPageLeave}
            onCancel={stayHere}
            confirmLabel={t('Common.UNSAVED_CHANGES_DISCARD')}
            cancelLabel={t('Common.UNSAVED_CHANGES_GO_BACK')}
        />
    </>);
}

export default PageLeaveGuard;
