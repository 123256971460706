import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import FullWeekdayOption from '../enums/FullWeekdayOption';
import '../style/Week.scss';

function Week({
                  availableDays: availableDaysInput,
                  selectedDays,
                  disabledDays = [],
                  onSelectWeekday,
                  onUpdateWeekdayArray,
                  errorMessage,
                  isValid,
                  small
              }) {
    const [t] = useTranslation();
    const availableDays = useMemo(() => {
        if (availableDaysInput) {
            // availableDaysInput may be unsorted; use FullWeekdayOption to define the order
            return Object.values(FullWeekdayOption).filter(day => availableDaysInput.findIndex(avDay => avDay === day) >= 0);
        } else {
            return Object.values(FullWeekdayOption);
        }
    }, [availableDaysInput]);

    const handleWeekdayClick = useCallback(weekday => {
        if (disabledDays.includes(weekday)) {
            return;
        }

        // Notify of the single click:
        if (onSelectWeekday) {
            onSelectWeekday(weekday);
        }

        // Calculate the new array and pass it on:
        const nullSafeSelectedDays = selectedDays ?? [];
        if (onUpdateWeekdayArray) {
            const foundIndex = (nullSafeSelectedDays).indexOf(weekday);
            if (foundIndex >= 0) {
                const selectedDaysCopy = [ ...nullSafeSelectedDays ];
                selectedDaysCopy.splice(foundIndex, 1);
                onUpdateWeekdayArray(selectedDaysCopy);
            } else {
                const newWeekdays = [...nullSafeSelectedDays, weekday]
                onUpdateWeekdayArray(newWeekdays);
            }
        }
    }, [onSelectWeekday, onUpdateWeekdayArray, selectedDays, disabledDays]);

    const createWeekdayStateClassNames = useCallback(day => {
        const isSelected = (selectedDays ?? []).indexOf(day) > -1;
        const isDisabled = (disabledDays ?? []).indexOf(day) > -1;

        let classNames = '';
        if (isSelected) {
            classNames = classNames.concat(' weekday-selected');
        }
        if (isDisabled) {
            classNames = classNames.concat(' weekday-disabled');
        }

        return classNames;
    }, [selectedDays, disabledDays]);

    return (
        <>
            <ul className={`week${isValid === false ? ' is-invalid' : ''} ${small ? 'small' : ''}`}>
                {
                    availableDays.map(day =>
                            <li
                                className={`weekday${onSelectWeekday || onUpdateWeekdayArray ? ' clickable' : ''}${createWeekdayStateClassNames(day)}`}
                                onClick={() => handleWeekdayClick(day)}
                                key={day}
                                role={onSelectWeekday || onUpdateWeekdayArray ? 'option' : null}
                            >
                                {t(`Weekday.${day}`).substr(0, 2)}
                            </li>
                        )
                }
            </ul>
            {
                isValid === false && errorMessage &&
                <div className="text-danger small">{errorMessage}</div>
            }
        </>
    );
}

export default Week;
