import {httpClient} from '../../common/api/HttpClient';

export const userActivityApi = {
    findMyActivities: (pageIndex, pageSize) => httpClient.get('user-activity/me', {
        params: {
            pageIndex,
            pageSize
        }
    }),
    findInstitutionActivities: (institutionId, pageIndex, pageSize) => httpClient.get(`user-activity/institution/${institutionId}`, {
        params: {
            pageIndex,
            pageSize
        }
    })
};

export default userActivityApi;
