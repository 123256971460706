import React from 'react';
import {useTranslation} from 'react-i18next';
import ErrorCode from '../../enums/ErrorCode';
import TextInput from './TextInput';

function LoginCredentials({
                              login,
                              onChange,
                              validation,
                              readOnly = false,
                              showEmail = true,
                              emailReadOnly,
                              showPassword = true,
                              isPasswordRequired = true,
                              mailErrorCode,
                              passwordLabel = 'User.PASSWORD',
                              showPasswordRepeatInOneLine = true
                          }) {
    const [t] = useTranslation();

    function getMailErrorMessage() {
        if (!validation.emailAddress && !!login.emailAddress) {
            return t('Validation.INVALID_EMAIL');
        }
        if (mailErrorCode) {
            const errorMessage = Object.keys(ErrorCode).find(code => ErrorCode[code].code === mailErrorCode);
            return t(`Error.${errorMessage}`);
        }
        return null;
    }

    return (
        <>
            {
                showEmail &&
                <TextInput
                    id="email"
                    type="email"
                    label={t('User.EMAIL')}
                    value={login.emailAddress}
                    onChange={value => onChange('emailAddress', value)}
                    required={!emailReadOnly}
                    isValid={validation.emailAddress && !mailErrorCode}
                    errorMessage={getMailErrorMessage()}
                    readonly={emailReadOnly}
                />
            }
            {
                showPassword &&
                <div className="row">
                    <div className={`col-12 ${showPasswordRepeatInOneLine ? 'col-lg-6' : ''}`}>
                        <TextInput
                            id="password"
                            type="password"
                            autoComplete="new-password"
                            label={t(passwordLabel)}
                            value={login.password}
                            onChange={value => onChange('password', value)}
                            required={isPasswordRequired || !!login.passwordRepeat}
                            isValid={validation.password}
                            readonly={readOnly}
                        />
                    </div>
                    <div className={`col-12 ${showPasswordRepeatInOneLine ? 'col-lg-6' : ''}`}>
                        <TextInput
                            id="passwordRepeat"
                            type="password"
                            label={t(`${passwordLabel}_REPEAT`)}
                            value={login.passwordRepeat}
                            onChange={value => onChange('passwordRepeat', value)}
                            required={isPasswordRequired || !!login.password}
                            errorMessage={login.passwordRepeat && login.password !== login.passwordRepeat ? t('Error.PASSWORD_MISMATCH') : null}
                            isValid={validation.passwordRepeat}
                            readonly={readOnly}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default LoginCredentials;
