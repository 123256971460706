import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {hideLoader, showLoader, setLoaderHint} from '../action/LoaderAction';

function PlaygroundArea() {
    const dispatch = useDispatch();
    const handleClick = useCallback((event) => {
        event.preventDefault();
        dispatch(showLoader());
        setTimeout(() => {
            dispatch(hideLoader());
        }, 1000 * 10);
    }, [dispatch]);

    const handleClickForLongAction = useCallback((event) => {
        event.preventDefault();
        dispatch(setLoaderHint());
        dispatch(showLoader());
        setTimeout(() => {
            dispatch(hideLoader());
        }, 1000 * 10);
    }, [dispatch]);

    return (
        <div className="container">
            This is the playground area. Have fun!
            <div className="mt-3">
                <div className="btn btn-primary" onClick={handleClick}>Loader!</div>
                <div className="btn btn-secondary ml-2" onClick={handleClickForLongAction}>Trigger long action</div>
            </div>
        </div>
    );
}

export default PlaygroundArea;
