import moment from 'moment';
import React, {useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {isWelfareActiveForDate, isWelfareInProgressForDate} from '../../../child/util/ChildUtils';
import Menu from '../../../common/component/meal/Menu';
import DefaultPopover from '../../../common/component/popover/DefaultPopover';
import Price from '../../../common/component/Price';
import CheckIconFilled from '../../../common/icons/CheckIconFilled';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import Icon from '../../../common/icons/Icon';
import PlusIcon from '../../../common/icons/PlusIcon';
import '../../../common/style/Meal.scss';
import {formatDate} from '../../../common/util/DateUtil';
import '../../style/OrderableMeal.scss';
import {getClosingDayText} from '../../util/OrderMenuUtil';
import NotOrderableReason from './NotOrderableReason';
import OrderOrUpdateMealWithLimit from './OrderOrUpdateMealWithLimit';

function OrderableChildMeal({
                                meals,
                                showDate,
                                menu,
                                menuGroup,
                                onAdd,
                                onUpdate,
                                onRemove,
                                childWelfare
                            }) {
    const [t] = useTranslation();
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);
    const ref = useRef(null);
    const isInVerifiedWelfare = useMemo(() => isWelfareActiveForDate(childWelfare, menu.date), [childWelfare, menu.date]);
    const isWelfareInProgress = useMemo(() => isWelfareInProgressForDate(childWelfare, menu.date), [childWelfare, menu.date]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [menu, meals]);

    if ((menuGroup.deactivationDate && new Date(menuGroup.deactivationDate) <= new Date(menu.date)) ||
        menu?.empty ||
        !menu ||
        !menu.meals ||
        !menu.meals.length || menu.locked) {
        return (

            <div className="col-sm">
                <div className="meal orderable" ref={ref}>
                    {
                        showDate &&
                        <div className="meal-date">{formatDate(currentLanguage, menu.date, true)}</div>
                    }
                    <div className="card" style={{height: showDate ? 'calc(100% - 1.4rem)' : ''}}>
                        <div className="card-header">
                            {
                                moment(menu.date).isBefore(moment.now())
                                    ? <div className="text-muted">{menuGroup.name}</div>
                                    : <>{menuGroup.name}</>
                            }
                        </div>
                        <div className="card-footer">
                            <div
                                className="text-muted no-change-possible mt-4">{getClosingDayText(menu, t)}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const order = menu.order;

    let allergens = [];
    menu.meals.filter(m => !!m.allergens)
        .map(m => allergens = allergens.concat(m.allergens));

    function getShowNotChangeable() {
        if (order) {
            if (!order.cancelable) {
                return true;
            }
        } else {
            if (!menu.orderable) {
                return true;
            }
        }
        return false;
    }

    return (
        <div className="col-sm">
            <div className={`meal orderable${order ? ' is-ordered' : ''}`} ref={ref}>
                {
                    showDate &&
                    <div className="meal-date">{formatDate(currentLanguage, menu.date, true)}</div>
                }
                <div className="card" style={{height: showDate ? 'calc(100% - 1.4rem)' : ''}}>
                    <div className="card-header">
                        {
                            order &&
                            <Icon src={<CheckIconFilled/>}
                                  title={t('Order.EXISTS')}
                                  className="float-right text-success"
                            />
                        }
                        {
                            moment(menu.date).isBefore(moment.now())
                                ? <div className="text-muted">{menuGroup.name}</div>
                                : <>{menuGroup.name}</>
                        }
                    </div>
                    {
                        menu.meals && menu.meals.length &&
                        <div className="card-body">
                            <Menu meals={menu.meals}/>
                        </div>
                    }
                    <div className="card-footer pt-2">
                        <div className="mb-2 row">
                            <div className="col-5">
                                {
                                    !!allergens.length &&
                                    <DefaultPopover placement="bottom">
                                        <DefaultPopover.Trigger>
                                            <a href="#" className="small text-muted">{t('Meal.ALLERGENS')}</a>
                                        </DefaultPopover.Trigger>
                                        <DefaultPopover.Content>
                                            <small>{allergens.map(a => a.name).join(', ')}</small>
                                        </DefaultPopover.Content>
                                    </DefaultPopover>
                                }
                            </div>

                            {
                                menu.orderable && menu.price &&
                                <div className="col-7">
                                    {isInVerifiedWelfare ? <small>{t('But.SUBSIDIZED')}</small> : <Price price={order ? order.singleValue : menu.price}/>}
                                </div>
                            }
                        </div>
                        {
                            !menu.orderable && !order &&
                            <div className="small text-center">{t('MealPlan.NO_MEAL_ORDERED')}</div>
                        }
                        <div className="text-right">
                            {
                                menu.menuLimit > 1 && !isInVerifiedWelfare && !isWelfareInProgress ?
                                    <OrderOrUpdateMealWithLimit
                                        meals={meals}
                                        menu={menu}
                                        menuGroup={menuGroup}
                                        onAddOrUpdate={onUpdate}
                                        onRemove={() => onRemove(menu)}
                                        notChangeable={getShowNotChangeable()}
                                    /> :
                                    <>
                                        {
                                            !order && menu.orderable &&
                                            <button className="btn btn-block btn-primary btn-sm" onClick={() => onAdd(1)}>
                                                <Icon src={<PlusIcon/>} className="mr-2"/> {t('Button.BUY')}
                                            </button>
                                        }
                                        {
                                            !order && !menu.orderable && menu.notOrderableReason === NotOrderableReason.PARALLEL_MENU_GROUP_LIMIT &&
                                            <button className="btn btn-block btn-primary btn-sm" disabled>
                                                <Icon src={<PlusIcon/>} className="mr-2"/> {t('Button.BUY')}
                                            </button>
                                        }
                                        {
                                            order && order.cancelable &&
                                            <button className="btn btn-block btn-light btn-sm" onClick={onRemove}>
                                                <Icon src={<DeleteIcon/>} className="mr-2"/> {t('Button.REMOVE')}
                                            </button>
                                        }
                                        {
                                            order && !order.cancelable &&
                                            <div className="text-muted small no-change-possible">{t('MealPlan.CANCEL_DEADLINE_EXCEEDED')}</div>
                                        }
                                        {
                                            !order && !menu.orderable && menu.notOrderableReason === NotOrderableReason.DEADLINE &&
                                            <div className="text-muted small no-change-possible">{t('MealPlan.ORDER_DEADLINE_EXCEEDED')}</div>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderableChildMeal;
