import React, {MouseEventHandler} from 'react';
import '../../style/StickyContent.scss';
import './FormButtons.scss';
import {useTranslation} from 'react-i18next';
import CancelButton from '../CancelButton';
import ConfirmButton from '../ConfirmButton';

interface Props {
    sticky?: boolean,
    onSave: MouseEventHandler<HTMLButtonElement>,
    onCancel: MouseEventHandler<HTMLButtonElement>,
    saveLabelKey?: string,
    saveDisabled?: boolean,
    className?: string
}

export default function FormButtons({sticky = true, onSave, onCancel, saveLabelKey, saveDisabled, className}: Readonly<Props>) {
    const [t] = useTranslation();

    return (
        <div className={`${sticky ? 'sticky-bottom form-button-margin' : ''} d-inline-flex float-right ${className ?? ''}`}>
            <CancelButton onClick={onCancel}/>
            <ConfirmButton onClick={onSave} label={t(saveLabelKey ?? 'Button.SAVE')} disabled={saveDisabled}/>
        </div>
    );
}
