import React, {useCallback, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import accountApi from '../../account/api/accountApi';
import PlateImage from '../../assets/img/PlateImage';
import Card from '../../common/component/Card';
import PageTitleWithWallet from '../../common/component/PageTitleWithWallet';
import BillIcon from '../../common/icons/BillIcon';
import CardIcon from '../../common/icons/CardIcon';
import CheckIconFilled from '../../common/icons/CheckIconFilled';
import HistoryIcon from '../../common/icons/HistoryIcon';
import Icon from '../../common/icons/Icon';
import PayoutIcon from '../../common/icons/PayoutIcon';
import WalletCreditCardIcon from '../../common/icons/WalletCreditCardIcon';
import {DefaultState} from '../../common/reducer/reducers';
import paymentProviderIntegrationApi from '../api/paymentProviderIntegrationApi';
import PendingPaymentsNotice from './PendingPaymentsNotice';

function ParentFinanceOverview() {
    const [t] = useTranslation();
    const history = useHistory();
    const [isDirectDebitEnabled, setIsDirectDebitEnabled] = useState();
    const [sepaMandateActive, setSepaMandateActive] = useState(false);
    const financeConfig = useSelector((state: DefaultState) => state.financeConfig.financeConfig)
    const account = useSelector((state: DefaultState) => state.user.account);

    const loadSepaMandate = useCallback(() => {
        if (account) {
            paymentProviderIntegrationApi.fetchSepaMandateForAccount(account.id).then(response => {
                if (response.data.success && response.data.result) {
                    setSepaMandateActive(!!response.data.result.active);
                }
            });
        }

    }, [account]);

    useEffect(() => {
        loadSepaMandate();
    }, [loadSepaMandate]);

    useEffect(() => {
        if (account) {
            accountApi.fetchPaymentSettings(account.id).then(response => {
                if (response.data.success) {
                    setIsDirectDebitEnabled(response.data.result.isDirectDebitEnabled);
                }
            });
        }
    }, [account]);

    const gotoStripePayment = () => {
        history.push('/finances/one-time-payment');
    };

    const gotoBalanceWithdrawal = () => {
        history.push('/finances/balance-withdrawal');
    };

    const gotoSepaMandate = () => {
        history.push('/finances/sepa-mandate');
    };

    const gotoBills = () => {
        history.push('/finances/bills');
    };

    const gotoHistory = () => {
        history.push('/finances/transaction-history');
    };

    return <>
        <div className="container finance">
            <div className="center">
                <PageTitleWithWallet headerTitle={t('Finance.TITLE')} balance={account?.balance} displayBig={true}/>
                {
                    financeConfig.FINANCE_INFO_ACTIVE &&
                    <div className="my-3">
                        <div className="alert-success p-3">
                            <Trans i18nKey="Finance.FINANCE_INFO"
                                   components={{b: <b/>}}
                            />
                        </div>
                    </div>
                }

                <PendingPaymentsNotice/>

                <div className="row">
                    {
                        isDirectDebitEnabled &&
                        <div className="col-12 col-md-6 col-xl-4">
                            <Card className="card-btn" onClick={gotoSepaMandate} title={t('Finance.SEPA_DIRECT_DEBIT_MANDATE')} icon={<CardIcon/>}
                                  showExtraInfo={sepaMandateActive} infoText={t('Finance.RECOMMENDED')} infoIcon={<CheckIconFilled/>}/></div>
                    }
                    <div className="col-12 col-md-6 col-xl-4">
                        <Card className="card-btn" onClick={gotoStripePayment} title={t('Finance.PAYMENT_TITLE')} icon={<WalletCreditCardIcon/>}/>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <Card className="card-btn" onClick={gotoBalanceWithdrawal} title={t('Finance.BALANCE_WITHDRAWAL.TITLE')}
                              icon={<PayoutIcon/>}/></div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <Card className="card-btn" onClick={gotoBills} title={t('Finance.BILLS')} icon={<BillIcon/>}/>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <Card className="card-btn" onClick={gotoHistory} title={t('Finance.TRANSACTION_HISTORY')} icon={<HistoryIcon/>}/>
                    </div>
                </div>
            </div>
        </div>
        <Icon className="background-plate-image" src={<PlateImage/>}/>
    </>;
}

export default ParentFinanceOverview;
