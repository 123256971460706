import React from 'react';

type  Prop = {
    size?: number;
}

const ArrowRightIcon = ({
                            size = 1
                        }: Prop) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M10.7673 0.20889C10.4674 -0.0768002 9.9926 -0.06525 9.7069 0.23467C9.4212 0.5346 9.4328 1.00933 9.7327 1.29502L15.9841 7.2496H1.25C0.83579 7.2496 0.5 7.5854
                  0.5 7.9996C0.5 8.4138 0.83579 8.7496 1.25 8.7496H15.9842L9.7327 14.7043C9.4328 14.99 9.4212 15.4648 9.7069 15.7647C9.9926 16.0646 10.4674 16.0762 10.7673
                  15.7905L18.1862 8.7238C18.3551 8.5629 18.4551 8.3576 18.4861 8.1443C18.4952 8.0975 18.5 8.0491 18.5 7.9996C18.5 7.9501 18.4952 7.9016 18.486 7.8547C18.455
                  7.6415 18.355 7.4364 18.1862 7.2756L10.7673 0.20889Z"/>
        </svg>
    );
};

export default ArrowRightIcon;
