import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import groupApi from '../../administration/api/groupApi';
import transactionApi from '../../administration/api/transactionApi';
import {markChanges} from '../../common/action/pageLeaveActions';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import TextInput from '../../common/component/form/TextInput';
import TextOutput from '../../common/component/TextOutput';
import CloseIcon from '../../common/icons/CloseIcon';
import Icon from '../../common/icons/Icon';
import '../style/GroupInput.scss';

let timeout = null;

function GroupInput({
                        group,
                        groupCode,
                        isValid,
                        onChangeGroup,
                        childName,
                        childId,
                        catererId,
                        required = true
                    }) {
    const STANDARD_GROUP_CODE_LENGTH = 8;
    const [t] = useTranslation();
    const [selectedGroupCode, setSelectedGroupCode] = useState(group ? group.code : groupCode);
    const [showError, setShowError] = useState(false);
    const [showInput, setShowInput] = useState(!group);
    const [showLoader, setShowLoader] = useState(false);
    const [hasGroupCodeChange, setHasGroupCodeChange] = useState(false);
    const [showGroupEditConfirmation, setShowGroupEditConfirmation] = useState(false);
    const dispatch = useDispatch();

    const loadGroup = useCallback(() => {
        setShowError(false);
        setShowLoader(true);
        if (selectedGroupCode) {
            groupApi.getGroupByCode(selectedGroupCode).then((res) => {
                const result = res.data.result;
                if (res.data.success && result !== null) {
                    if (result.catererId !== catererId) {
                        setShowError(true);
                        setShowInput(true);
                    } else {
                        setShowError(false);
                        setShowInput(false);
                        onChangeGroup(result, false);
                        setHasGroupCodeChange(false);
                    }
                } else {
                    setShowError(selectedGroupCode.length >= STANDARD_GROUP_CODE_LENGTH || result === null);
                    setShowInput(true);
                }
                setShowLoader(false);
            });
        }
    }, [onChangeGroup, selectedGroupCode, catererId]);

    useEffect(() => {
        // Delay request after input
        if (timeout) {
            clearTimeout(timeout);
        }

        if (selectedGroupCode && hasGroupCodeChange) {
            timeout = setTimeout(loadGroup, 1000);
        }
    }, [selectedGroupCode, hasGroupCodeChange, loadGroup]);

    function onChangeGroupInput(value) {
        setSelectedGroupCode(value.trim());
        dispatch(markChanges());
        setHasGroupCodeChange(true);
    }

    function switchEditMode() {
        setShowInput(true);
    }

    function cancel() {
        setShowInput(false);
        onChangeGroup(group, false);
        setSelectedGroupCode(group.code);
    }

    function handleChangeGroup() {
        if (childId) {
            transactionApi.existsCancelableOrderOrSubscription(childId).then(res => {
                if (res.data.success) {
                    if (res.data.result) {
                        setShowGroupEditConfirmation(true);
                    } else {
                        switchEditMode();
                    }
                }
            });
        } else {
            switchEditMode();
        }
    }

    return (
        <div className="group-input">
            {
                /* Edit form */
                showInput &&
                <div className="row align-items-center no-gutters">
                    <div className="col">
                        <TextInput
                            label={t('Child.GROUP_CODE')}
                            value={selectedGroupCode}
                            required={required}
                            isValid={!showError && isValid}
                            errorMessage={showError ? t('Error.INVALID_INSTITUTION') : null}
                            showError={showError}
                            onChange={onChangeGroupInput}
                            showLoader={showLoader}
                        />
                    </div>
                    {
                        group &&
                        <div className="col-2 col-sm-1 text-right">
                            <button
                                className="btn btn-secondary btn-sm"
                                onClick={cancel}
                                title={t('Button.CANCEL')}>
                                <Icon src={<CloseIcon/>}/>
                            </button>
                        </div>
                    }
                </div>
            }
            {
                /* Display group */
                !showInput && group &&
                <div className="row align-items-center">
                    <div className="col-8">
                        <TextOutput label={t('Institution.GROUP')}>
                            {group.name}
                            <div className="small text-muted">{group.institutionName}</div>
                        </TextOutput>
                    </div>
                    {
                        !showInput &&
                        <div className="col-4 text-right">
                            <button
                                onClick={handleChangeGroup}
                                className="btn btn-primary btn-sm">
                                {t('Child.CHANGE_GROUP')}
                            </button>
                        </div>
                    }
                </div>
            }
            <ConfirmationDialog
                open={showGroupEditConfirmation}
                title={t('Group.CONFIRM_DIALOG_HEADER')}
                body={t('Group.CONFIRM_DIALOG_BODY', {child: childName})}
                confirmLabel={t('Button.CONTINUE')}
                cancelLabel={t('Button.CANCEL')}
                onConfirm={() => {
                    switchEditMode();
                    setShowGroupEditConfirmation(false);
                }}
                onCancel={() => setShowGroupEditConfirmation(false)}
            />
        </div>
    );
}

export default GroupInput;
