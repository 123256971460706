import {Dispatch} from 'redux';
import catererApi from '../../../caterer/api/catererApi';
import {AppThunk} from '../../../common/reducer/reducers';
import {CatererType} from '../../../types/caterer/CatererType';
import {ParentType} from '../../../types/ParentType';

export const SET_CATERERS = 'SET_CATERERS';
export const SET_PARENTS_SEARCH_TERM = 'SET_PARENTS_SEARCH_TERM';
export const SET_PARENTS_SEARCH_CATERER_ID = 'SET_PARENTS_SEARCH_CATERER_ID';
export const SET_PARENTS_SEARCH_RESULT = 'SET_PARENTS_SEARCH_RESULT';
export const RESET_PARENTS_SEARCH_EXECUTED = 'RESET_PARENTS_SEARCH_EXECUTED';
export const RESET_PARENTS_SEARCH = 'RESET_PARENTS_SEARCH';
export const REMOVE_FROM_PARENTS_SEARCH_RESULT = 'REMOVE_FROM_PARENTS_SEARCH_RESULT';

export interface SetCaterersForSupportUserAction {
    type: typeof SET_CATERERS;
    caterers: Array<CatererType>;
}

interface SetParentsSearchTermAction {
    type: typeof SET_PARENTS_SEARCH_TERM;
    searchTerm: string;
}

interface SetParentsSearchCatererIdAction {
    type: typeof SET_PARENTS_SEARCH_CATERER_ID;
    catererId: number;
}

interface SetParentsSearchResultAction {
    type: typeof SET_PARENTS_SEARCH_RESULT;
    parents: Array<ParentType>;
}

interface ResetParentsSearchExecutedAction {
    type: typeof RESET_PARENTS_SEARCH_EXECUTED;
}

interface ResetParentsSearchAction {
    type: typeof RESET_PARENTS_SEARCH;
}

interface RemoveFromParentsSearchResultAction {
    type: typeof REMOVE_FROM_PARENTS_SEARCH_RESULT;
    parentIds: Array<number>
}

export type SupportDataActions = SetCaterersForSupportUserAction | SetParentsSearchTermAction | SetParentsSearchCatererIdAction
    | SetParentsSearchResultAction | ResetParentsSearchExecutedAction | ResetParentsSearchAction | RemoveFromParentsSearchResultAction;

export const fetchCaterers = (): AppThunk => (dispatch: Dispatch<SetCaterersForSupportUserAction>) => {
    fetchCaterersForSupportUser(dispatch);
};

// Not as AppThunk because this method is only called internally from other action methods:
export function fetchCaterersForSupportUser(dispatch: Dispatch<SetCaterersForSupportUserAction>) {
    catererApi.fetchActiveCaterers().then(response => {
        if (response.data.success) {
            dispatch({type: 'SET_CATERERS', caterers: response.data.result});
        }
    });
}

export const setParentsSearchTerm = (searchTerm: string): AppThunk => (dispatch: Dispatch<SetParentsSearchTermAction>) => {
    dispatch({type: 'SET_PARENTS_SEARCH_TERM', searchTerm});
}

export const setParentsSearchCatererId = (catererId: number): AppThunk => (dispatch: Dispatch<SetParentsSearchCatererIdAction>) => {
    dispatch({type: 'SET_PARENTS_SEARCH_CATERER_ID', catererId});
}

export const setParentsSearchResult = (parents: Array<ParentType>): AppThunk => (dispatch: Dispatch<SetParentsSearchResultAction>) => {
    dispatch({type: 'SET_PARENTS_SEARCH_RESULT', parents});
};

export const resetParentsSearchExecuted = (): AppThunk => (dispatch: Dispatch<ResetParentsSearchExecutedAction>) => {
    dispatch({type: 'RESET_PARENTS_SEARCH_EXECUTED'});
};

export const resetParentsSearch = (): AppThunk => (dispatch: Dispatch<ResetParentsSearchAction>) => {
    dispatch({type: 'RESET_PARENTS_SEARCH'});
};

export const removeFromParentsSearchResult = (parentIds: Array<number>): AppThunk => (dispatch: Dispatch<RemoveFromParentsSearchResultAction>) => {
    dispatch({type: 'REMOVE_FROM_PARENTS_SEARCH_RESULT', parentIds});
};
