import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {TransactionTypeEnum} from '../../types/enums/transaction/TransactionTypeEnum';
import {DefaultState} from '../reducer/reducers';
import '../style/Price.scss';

interface Prop {
    price: number | undefined,
    signed?: boolean,
    transactionType?: TransactionTypeEnum,
    displayBig?: boolean,
    textRight?: boolean,
    labelLeft?: string | null,
    hasColor?: boolean,
    isThin?: boolean
}

function Price(props: Prop) {
    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const textRight = props.textRight === undefined ? true : props.textRight;
    const hasColor = props.hasColor === undefined ? true : props.hasColor;

    let realPrice = props.price;

    if (!realPrice && realPrice !== 0) {
        return null;
    }

    const formatter = new Intl.NumberFormat(currentLanguage, {
        style: 'currency',
        currency: 'EUR'
    });
    const sign = realPrice >= 0 ? '+ ' : '';

    if (props.transactionType) {
        // 0 * -1 would be formatted as -0,00 €
        realPrice = TransactionTypeEnum.DEBIT === props.transactionType && realPrice !== 0 ? realPrice * -1 : realPrice;
    }

    const priceColor = hasColor ? realPrice >= 0 ? 'credit' : 'debit' : '';
    const fontWeight = props.isThin ? 'thin' : 'bold';
    return (
        <>
            {
                props.labelLeft &&
                <div className="mr-1">
                    {t(props.labelLeft)}
                </div>
            }
            <div className={`${props.displayBig ? 'price-big' : 'price-small'} ${textRight ? 'text-right' : ''} price ${priceColor} ${fontWeight} `}>
                <div>
                    {`${props.signed ? sign : ''}`}
                    {formatter.format(realPrice)}
                </div>
            </div>
        </>
    );
}

export default Price;
