import React, {lazy, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import AccountConfirmation from './account/component/AccountConfirmation';
import Inquiry from './account/component/Inquiry';
import ParentRegistration from './account/component/ParentRegistration';
import PasswordReset from './account/component/passwordReset/PasswordReset';
import PasswordResetRequest from './account/component/passwordReset/PasswordResetRequest';
import Profile from './administration/component/Profile';
import ChildEdit from './child/component/ChildEdit';
import {pageClicked} from './common/action/pageClickAction';
import {httpClient} from './common/api/HttpClient';
import {setGlobalRequestInterceptor, setGlobalResponseInterceptor} from './common/api/HttpInterceptors';
import PlaygroundArea from './common/component/PlaygroundArea';
import Permission from './common/enums/Permission';
import Role from './common/enums/Role';
import {DefaultState} from './common/reducer/reducers';
import {useHasPermission} from './common/util/PermissionUtil';
import Dashboard from './dashboard/component/Dashboard';
import BalanceWithdrawal from './finance/component/BalanceWithdrawal';
import ParentBillOverview from './finance/component/ParentBillOverview';
import ParentFinanceOverview from './finance/component/ParentFinanceOverview';
import PaymentConfirmationPage from './finance/component/PaymentConfirmationPage';
import SepaDirectDebitMandate from './finance/component/SepaDirectDebitMandate';
import StripePaymentSelection from './finance/component/stripe/StripePaymentSelection';
import TransactionHistory from './finance/component/TransactionHistory';
import Imprint from './information/component/Imprint';
import PrivacyPolicy from './information/component/PrivacyPolicy';
import TermsAndConditions from './information/component/TermsAndConditions';
import Messages from './message/component/Messages';
import Onboarding from './onboarding/component/Onboarding';
import ChildOrder from './order/component/childOrder/ChildOrder';
import {checkState} from './user/action/userActions';
import Goodbye from './user/component/Goodbye';
import Login from './user/component/Login';
import UserActivityOverview from './useractivity/component/UserActivityOverview';

// Lazy-load common components:
const FaqAndContact = lazy(() => import('./information/component/FaqAndContact'));

// Lazy-load institution components:
const HandoutMeals = lazy(() => import(/* webpackChunkName: "nonparent" */ './handout/component/HandoutMeals'));
const GroupDetail = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/groups/GroupDetail'));
const Institution = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/institution/Institution'));
const InstitutionContactPersonProfile = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/institution/InstitutionContactPersonProfile'));
const InstitutionViewDetermination = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/institution/InstitutionViewDetermination'));

// Lazy-load caterer components:
const BillingAddressEdit = lazy(() =>  import(/* webpackChunkName: "nonparent" */ './administration/component/but/BillingAddressEdit'));
const BillingAddressList = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/but/BillingAddressList'));
const CatererBillOverview = lazy(() => import(/* webpackChunkName: "nonparent" */ './caterer/bills/component/CatererBillOverview'));
const CatererEdit = lazy(() => import(/* webpackChunkName: "nonparent" */ './caterer/component/CatererEdit'));
const Communication = lazy(() => import(/* webpackChunkName: "nonparent" */ './communication/component/Communication'));
const CreditAdministration = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/credit/CreditAdministration'));
const DeliveryGroupEdit = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/deliverygroup/DeliveryGroupEdit'));
const DeliveryGroupList = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/deliverygroup/DeliveryGroupList'));
const InstitutionList = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/institution/InstitutionList'));
const MenuOverview = lazy(() => import(/* webpackChunkName: "nonparent" */ './menu/component/MenuOverview'));
const RecipeEdit = lazy(()  => import(/* webpackChunkName: "nonparent" */ './menu/component/RecipeEdit'));
const TaskBoard = lazy(() => import(/* webpackChunkName: "nonparent" */ './taskboard/component/TaskBoard'));
const TaskEdit = lazy(() => import(/* webpackChunkName: "nonparent" */ './taskboard/component/TaskEdit'));

// Lazy-load admin components:
const AdminArea = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/admin/AdminArea'));
const CarrierEdit = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/carrier/CarrierEdit'));
const CarrierList = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/carrier/CarrierList'));
const CatererList = lazy(() => import(/* webpackChunkName: "nonparent" */ './caterer/component/CatererList'));
const UserEdit = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/user/UserEdit'));
const UserList = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/component/user/UserList'));

// Lazy-load support components:
const CaterersManagement = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/support/component/CaterersManagement'));
const InstitutionsManagement = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/support/component/InstitutionsManagement'));
const ParentManagementOverview = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/support/component/ParentManagementOverview'));
const ParentsManagement = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/support/component/ParentsManagement'));
const SupportProfile = lazy(() => import(/* webpackChunkName: "nonparent" */ './administration/support/component/SupportProfile'));

export const PATH_SUPPORT = '/support';
export const PATH_SUPPORT_PARENTS = PATH_SUPPORT + '/parents';

function AppRoutes() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state: DefaultState) => state.user.isLoggedIn);
    const role = useSelector((state: DefaultState) => state.user.login ? state.user.login.role : null);
    const hasInstitutionPermission = useHasPermission(Permission.READ_INSTITUTION);
    const hasCreditPermissionGlobal = useHasPermission(Permission.WRITE_ANY_CREDIT);
    const hasCreditPermissionRestricted = useHasPermission(Permission.WRITE_CREDIT_FOR_ASSOCIATED_PARENT);
    const hasCreditPermission = hasCreditPermissionGlobal || hasCreditPermissionRestricted;
    const hasCarrierPermission = useHasPermission(Permission.READ_CARRIER);
    const hasAccountPermission = useHasPermission(Permission.READ_ACCOUNT);
    const hasButPermission = useHasPermission(Permission.READ_BUT);
    const hasCatererPermission = useHasPermission(Permission.WRITE_CATERER);
    const hasEmailsReadPermission = useHasPermission(Permission.READ_EMAILS);
    const isInstitutionContact = role === Role.INSTITUTION;
    const isParent = role === Role.PARENT;
    const isCarrier = role === Role.CARRIER;
    const isCaterer = role === Role.CATERER;
    const isAdmin = role === Role.ADMINISTRATOR;
    const showBillOverview = isCaterer || isAdmin;
    const isPlaygroundEnabled = process.env.REACT_APP_ENVIRONMENT !== 'prod';
    const [checkedForNewTab, setCheckedForNewTab] = useState(false);
    const [initLoginStatus, setInitLoginStatus] = useState<boolean | null>(null);


    useEffect(() => {
        if (initLoginStatus === null) {
            setInitLoginStatus(isLoggedIn);
        }
    }, [initLoginStatus, isLoggedIn]);

    useEffect(() => {
        if (!initLoginStatus && window.sessionStorage.getItem('api-key')) {
            setGlobalRequestInterceptor(httpClient);
            setGlobalResponseInterceptor(httpClient);
            dispatch(checkState());
        } else {
            setCheckedForNewTab(true);
        }
    }, [dispatch, initLoginStatus]);

    useEffect(() => {
        if (initLoginStatus !== null && !initLoginStatus && isLoggedIn) {
            setCheckedForNewTab(true);
        }
    }, [initLoginStatus, isLoggedIn]);

    if (!checkedForNewTab) {
        return null;
    }

    let defaultPath = '/';

    if (isCaterer) {
        defaultPath = '/tasks';
    } else if (isAdmin) {
        defaultPath = '/admin';
    } else if (role === Role.SUPPORT) {
        defaultPath = PATH_SUPPORT_PARENTS;
    } else if (isInstitutionContact || hasInstitutionPermission) {
        defaultPath = '/institutions';
    }

    return (
        <div id="content" onClick={() => dispatch(pageClicked())}>
            <Messages/>
            {
                !isLoggedIn ?
                    <Switch>
                        <Route path="/goodbye" exact render={() => <Goodbye/>}/>
                        <Route path="/terms-and-conditions" exact render={() => <TermsAndConditions/>}/>
                        <Route path="/imprint" exact render={() => <Imprint/>}/>
                        <Route path="/privacy-policy" exact render={() => <PrivacyPolicy/>}/>
                        <Route path="/faq-and-contact" exact render={() => <FaqAndContact/>}/>
                        <Route path="/confirm" exact render={() => <AccountConfirmation/>}/>
                        <Route path="/request-password-reset" exact render={() => <PasswordResetRequest/>}/>
                        <Route path="/password-reset" exact render={() => <PasswordReset/>}/>
                        <Route path="/inquiry" exact render={() => <Inquiry/>}/>,
                        <Route path="/register" exact render={() => <ParentRegistration/>}/>
                        <Route exact={false}>
                            <Login/>
                        </Route>
                    </Switch>
                    :
                    <Switch>
                        <Route path="/terms-and-conditions" exact render={() => <TermsAndConditions/>}/>
                        <Route path="/imprint" exact render={() => <Imprint/>}/>
                        <Route path="/privacy-policy" exact render={() => <PrivacyPolicy/>}/>
                        <Route path="/faq-and-contact" exact render={() => <FaqAndContact/>}/>
                        {
                            (isCaterer || isAdmin) &&
                            [
                                <Route path="/tasks" key="/tasks" exact render={() => <TaskBoard/>}/>,
                                <Route path="/tasks/:id" key="/tasks/:id" exact render={() => <TaskEdit/>}/>
                            ]
                        }
                        {
                            hasCatererPermission && isAdmin &&
                            [
                                <Route path="/caterer" key="/caterer" exact render={() => <CatererList/>}/>,
                                <Route path="/caterer/:id" key="/caterer/:id" exact render={() => <CatererEdit profileMode={false}/>}/>
                            ]
                        }
                        {
                            (hasInstitutionPermission || isInstitutionContact) &&
                            [
                                <Route path={['/institutions/:id', '/institutions/:id/:subsection']} key="/institutions/:id" exact render={() => <Institution/>}/>,
                                <Route path="/group-detail/:placesOrders/:groupCode" key="/group-order/:groupCode" exact render={() => <GroupDetail/>}/>
                            ]
                        }
                        {
                            hasInstitutionPermission &&
                            <Route path="/institutions" key="/institutions" exact render={() => <InstitutionList/>}/>
                        }
                        {
                            isInstitutionContact &&
                            <Route path="/institutions" key="/institutions" exact render={() => <InstitutionViewDetermination/>}/>
                        }
                        {
                            hasCreditPermission &&
                            <Route path="/credits" exact render={() => <CreditAdministration/>}/>
                        }
                        {
                            (hasCarrierPermission || isCarrier) &&
                            [
                                <Route path="/carriers" key="/carriers" exact render={() => <CarrierList/>}/>,
                                <Route path="/carriers/:id" key="/carriers/:id" exact render={() => <CarrierEdit/>}/>
                            ]
                        }
                        {
                            hasAccountPermission &&
                            [
                                <Route path="/users" key="/users" exact render={() => <UserList/>}/>,
                                <Route path="/users/:id" key="/users/:id" exact render={() => <UserEdit/>}/>
                            ]
                        }
                        {
                            hasButPermission &&
                            [
                                <Route path="/billing-addresses" key="/billing-addresses" exact render={() => <BillingAddressList/>}/>,
                                <Route path="/billing-addresses/:id" key="/billing-addresses/:id" exact render={() => <BillingAddressEdit/>}/>
                            ]
                        }
                        {
                            (isAdmin || isCaterer) &&
                            [
                                <Route path="/delivery-groups" key="/delivery-groups" exact render={() => <DeliveryGroupList/>}/>,
                                <Route path="/delivery-groups/:id" key="/delivery-groups/:id" exact render={() => <DeliveryGroupEdit/>}/>
                            ]
                        }
                        {
                            showBillOverview &&
                            <Route path={['/bills', '/bills/:type']} exact render={() => <CatererBillOverview/>}/>
                        }
                        {
                            isParent &&
                            [
                                <Route path="/my-profile" key="/my-profile" exact render={() => <Profile/>}/>,
                                <Route path="/finances" key="/finances" exact render={() => <ParentFinanceOverview/>}/>,
                                <Route path="/finances/one-time-payment" key="/finances/one-time-payment" exact render={() => <StripePaymentSelection/>}/>,
                                <Route path="/finances/balance-withdrawal" key="/finances/balance-withdrawal" exact render={() => <BalanceWithdrawal/>}/>,
                                <Route path="/finances/bills" key="/finances/bills" exact render={() => <ParentBillOverview/>}/>,
                                <Route path="/finances/sepa-mandate" key="/sepa-mandate/sepa-direct-debit-mandate" exact render={() => <SepaDirectDebitMandate/>}/>,
                                <Route path="/finances/transaction-history" key="/finances/transaction-history" exact render={() => <TransactionHistory/>}/>,
                                <Route path="/order/:childId" key="/order/:childId" exact render={() => <ChildOrder/>}/>,
                                <Route path="/children/:id" key="/children/:id" exact render={() => <ChildEdit/>}/>,
                                <Route path="/activities" key="/activities" exact render={() => <UserActivityOverview/>}/>,
                                <Route path="/onboarding" key="/onboarding" exact render={() => <Onboarding/>}/>,
                                <Route path="/payment-confirmation" key="/payment-confirmation" exact render={() => <PaymentConfirmationPage/>}/>,
                                <Route path="/" key="/" exact render={() => <Dashboard/>}/>
                            ]
                        }
                        {
                            isCaterer &&
                            [
                                <Route path="/caterer-profile" key="/caterer-profile" exact render={() => <CatererEdit profileMode={true}/>}/>,
                                <Route path={['/menu', '/menu/:subsection']} key="/menu" exact render={() => <MenuOverview/>}/>,
                                <Route path="/recipe/:id" key="/recipe/:id" exact render={() => <RecipeEdit/>}/>,
                                <Route path="/handout/:institutionId" key="/handout/:institutionId" exact render={() => <HandoutMeals/>}/>
                            ]

                        }
                        {
                            isInstitutionContact &&
                            [
                                <Route path="/handout/:institutionId" key="/handout/:institutionId" exact render={() => <HandoutMeals/>}/>,
                                <Route path="/contact-profile" key="/contact-profile" exact render={() => <InstitutionContactPersonProfile/>}/>
                            ]
                        }
                        {
                            isPlaygroundEnabled &&
                            <Route path="/playground" key="/playground" exact render={() => <PlaygroundArea/>}/>
                        }
                        {
                            hasEmailsReadPermission &&
                            <Route path={['/communication', '/communication/:subsection']} exact render={() => <Communication/>}/>
                        }
                        {
                            isAdmin &&
                            <Route path="/admin" key="/admin" exact render={() => <AdminArea/>}/>
                        }
                        {
                            role === Role.SUPPORT &&
                            [
                                <Route path="/support/parents" key="/support/parents" exact render={() => <ParentsManagement/>}/>,
                                <Route path={['/support/parents/:parentId', '/support/parents/:parentId/:section']} key="/support/parents/:parentId" exact render={(props) => <ParentManagementOverview parentAccountId={props.match.params.parentId}/>}/>,
                                <Route path="/support/institutions" key="/support/institutions" exact render={() => <InstitutionsManagement/>}/>,
                                <Route path="/support/caterers" key="/support/caterers" exact render={() => <CaterersManagement/>}/>,
                                <Route path="/support/profile" key="/support/profile" exact render={() => <SupportProfile/>}/>
                            ]
                        }

                        <Redirect to={{pathname: defaultPath}}/>
                    </Switch>
            }
        </div>
    );
}

export default AppRoutes;
